<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <v-row align="center">
        <v-col cols="auto" class="pr-12">
          <h1>Categories</h1>
        </v-col>
        <v-col class="pl-12">
          <v-text-field
            label="Search Categories"
            v-model="searchTerm"
            outlined
            dense
            hide-details
            prepend-inner-icon="mdi-magnify"
          ></v-text-field>
        </v-col>
        <v-col cols="auto">
          <v-row align="center" justify="end" no-gutters>
            <v-btn
              depressed
              class="mr-2"
              :to="{ name: 'module-flightmode-products-categories-archived' }"
              >View Old Categories</v-btn
            >
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  depressed
                  color="accent"
                  v-on="on"
                  @click="$refs.categoryForm.openForm()"
                >
                  <v-icon small>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>Add New Category</span>
            </v-tooltip>
          </v-row>
        </v-col>
      </v-row>
      <v-data-table
        :headers="tableHeaders"
        :items="categories"
        no-data-text="No Categories found"
      >
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                x-small
                depressed
                color="blue lighten-4 blue--text"
                v-on="on"
                class="mr-2"
                @click="$refs.categoryForm.openForm(item)"
              >
                <v-icon x-small>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>Edit</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                x-small
                depressed
                color="red lighten-4 red--text"
                v-on="on"
                @click="openDelete(item)"
              >
                <v-icon x-small>mdi-archive</v-icon>
              </v-btn>
            </template>
            <span>Archive</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-container>
    <CategoryForm ref="categoryForm" />
    <v-dialog v-model="deleteDialog.open" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">Archive Category</v-card-title>
        <v-card-text
          >Are you sure you want to archive
          {{ deleteDialog.category.name }}?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" depressed @click="resetDelete">No, cancel</v-btn>
          <v-btn
            color="success"
            depressed
            :loading="deleteDialog.loading"
            @click="saveDelete"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import CategoryForm from "../components/CategoryForm";

export default {
  components: {
    CategoryForm,
  },

  data() {
    return {
      searchTerm: "",
      breadcrumbs: [
        {
          text: "Products",
          disabled: false,
          exact: true,
          to: {
            name: "module-flightmode-products",
          },
        },
        {
          text: "Categories",
          disabled: true,
        },
      ],
      tableHeaders: [
        { text: "Category", value: "name" },
        { text: "Actions", value: "actions", sortable: false, align: "right" },
      ],
      deleteDialog: {
        open: false,
        loading: false,
        category: {},
      },
    };
  },

  computed: {
    categories() {
      let categories = this.$store.state.flightmode.products["categories"];
      const searchTerm = this.searchTerm.toLowerCase();

      if (this.searchTerm !== "") {
        categories = categories.filter(c => {
          const name = c.name.toLowerCase();

          return name.includes(searchTerm);
        });
      }

      return categories;
    },
  },

  methods: {
    openDelete(category) {
      this.deleteDialog.category = category;
      this.deleteDialog.open = true;
    },

    resetDelete() {
      this.deleteDialog.loading = false;
      this.deleteDialog.open = false;
      this.deleteDialog.category = {};
    },

    saveDelete() {
      const appId = this.$route.params.id;
      this.deleteDialog.loading = true;

      this.$store
        .dispatch("flightmode/products/deleteCategory", {
          appId,
          categoryId: this.deleteDialog.category.id,
        })
        .then(() => this.resetDelete())
        .catch(() => (this.loading = false));
    },
  },
};
</script>
