<template>
  <v-dialog
    v-model="dialog"
    scrollable
    max-width="600px"
    @click:outside="resetForm()"
  >
    <v-form @submit.prevent="saveForm" method="post" id="category-form">
      <v-card>
        <v-card-title class="headline"
          >{{ isEditing ? "Edit" : "Add a new" }} Category</v-card-title
        >
        <v-divider></v-divider>
        <v-card-text class="pt-5">
          <v-text-field
            label="Category Name"
            v-model="fields.name"
            outlined
            :error="errors.hasOwnProperty('name')"
            :error-messages="errors['name']"
          ></v-text-field>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="resetForm()">Close</v-btn>
          <v-btn color="accent" text type="submit" :loading="loading"
            >Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      loading: false,
      isEditing: false,
      errors: {},
      category: {},
      fields: {
        name: null,
      },
    };
  },

  methods: {
    openForm: function(category = null) {
      if (category !== null) {
        this.isEditing = true;
        this.category = category;
        this.fields.name = category.name;
      }

      this.dialog = true;
    },

    saveForm: function() {
      this.loading = true;
      this.errors = {};

      let payload = {
        appId: this.$route.params.id,
        fields: this.fields,
        isEditing: this.isEditing,
      };

      if (this.isEditing) {
        payload.categoryId = this.category.id;
      }

      this.$store
        .dispatch("flightmode/products/saveCategory", payload)
        .then(() => {
          this.resetForm();
        })
        .catch(err => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },

    resetForm: function() {
      this.dialog = false;
      this.loading = false;
      this.isEditing = false;
      this.category = {};
      this.errors = {};
      this.fields = {
        name: null,
      };
    },
  },
};
</script>
